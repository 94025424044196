import { useState } from "react";
import { DndContext, closestCenter, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import uuid4 from "uuid4";

import { Button, Row } from "./components";

export const ContentBlock = () => {
  const [rows, setRows] = useState([]);

  // dnd
  const [draggedRowData, setDraggedRowData] = useState();
  const sensors = useSensors(
    useSensor(PointerSensor)
  );

  const handleDragEnd = (event) => {
    const {active, over} = event;

    const currentLevel = active.data.current.level;
    
    // level 1
    if (currentLevel === 1 && active.id !== over.id) {
      setRows((rows) => {
        const oldIndex = rows.findIndex(row => row.id === active.id);
        const newIndex = rows.findIndex(row => row.id === over.id);

        return arrayMove(rows, oldIndex, newIndex);
      });
    }

    // level 2
    if (currentLevel === 2 && active.id !== over.id) {
      const getReorderedChildrens = (childrens) => {
        if (!childrens) {
          return;
        }
        const oldIndex = childrens?.findIndex(child => child.id === active.id);
        const newIndex = childrens?.findIndex(child => child.id === over.id);

        return arrayMove(childrens, oldIndex, newIndex);
      }

      setRows(rows.map((item) => {
        return {
          ...item,
          childrens: getReorderedChildrens(item.childrens).filter(Boolean)
        }
      }));
    }
  }

  const handleAddNewSectionClick = () => {
    setRows([...rows, {
      id: uuid4(),
      type: 'section',
      isOpen: true,
      data: {
        jumpId: '',
        quickNaviMenuAnchorText: '',
      },
      childrens: []
    }]);
  }

  const handleAddNewElementCallback = (elementName, parentId) => {
    if (parentId) {
      setRows(rows.map((item) => {
        if (item.id === parentId) {
          return {
            ...item,
            childrens: [
              ...item.childrens,
              {
                id: uuid4(),
                type: 'element',
                isOpen: true,
                data: {
                  name: elementName
                }
              }
            ]
          }
        }
        return item;
      }));
      return;
    }

    setRows([...rows, {
      id: uuid4(),
      type: 'element',
      isOpen: true,
      data: {
        name: elementName
      }
    }]);
  }

  const toggleRow = (toggleRowId) => {
    setRows(rows.map((item) => {
      if (item.id === toggleRowId) {
        return {
          ...item,
          isOpen: !item.isOpen
        }
      }
      return {
        ...item,
        childrens: item.childrens?.map((child) => {
          if (child.id === toggleRowId) {
            return {
              ...child,
              isOpen: !child.isOpen
            }
          }
          return child
        })
      }
    }));
  }

  const toggleRows = (isOpen) => {
    setRows(rows.map((item) => ({
      ...item,
      isOpen,
      childrens: item.childrens?.map((element) => ({
        ...element,
        isOpen
      }))
    })));   
  }

  const toggleRowsInSections = (isOpen, sectionId) => {
    setRows(rows.map((item) => ({
      ...item,
      childrens: item.childrens?.map((child) => ({
        ...child,
        isOpen: item.id === sectionId ? isOpen : child.isOpen
      }))
    })));   
  }

  const handleDeleteButtonClick = (deleteRowId) => {
    setRows(rows.map((item) => {
      if (item.id === deleteRowId) {
        return false;
      }

      return {
        ...item,
        childrens: item.childrens?.map((element) => {
          if (element.id === deleteRowId) {
            return false;
          }
          return element
        }).filter(Boolean)
      }
    }).filter(Boolean));
  }

  const handleDataChange = (rowId, data) => {
    setRows(rows.map((item) => {
      if (item.id === rowId) {
        return {
          ...item,
          data: {
            ...item.data,
            ...data
          }
        }
      }
      return {
        ...item,
        childrens: item.childrens?.map((element) => {
          if (element.id === rowId) {
            return {
              ...element,
              data: {
                ...element.data,
                ...data
              }
            }
          }
          return element
        })
      }
    }));
  }

  return (
    <>
      <div className="flex flex-col gap-4">
        {!!rows?.length ? (
          <>
            <div className="flex gap-2">
              <Button className="ml-auto" onClick={() => toggleRows(true)}>Show all</Button>
              <Button onClick={() => toggleRows(false)}>Hide all</Button>
            </div>
            <div className="flex flex-col gap-2">
              <DndContext 
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
                modifiers={[restrictToVerticalAxis]}
              >
                <SortableContext 
                  items={rows}
                  strategy={verticalListSortingStrategy}
                >
                  {rows.map((row) => (
                    <Row
                      key={row.id}
                      id={row.id}
                      type={row.type}
                      isOpen={row.isOpen}
                      data={row.data}
                      childrens={row.childrens}
                      level={1}
                      draggedRowData={draggedRowData}
                      addElementCallBack={handleAddNewElementCallback}
                      handleDeleteButtonClick={handleDeleteButtonClick}
                      toggleOpen={toggleRow}
                      toggleOpenInSections={toggleRowsInSections}
                      setDraggedRowData={setDraggedRowData}
                      handleDataChange={handleDataChange}
                    />
                  ))}
                </SortableContext>
              </DndContext>
            </div>
            <div className="flex gap-2">
              <Button onClick={handleAddNewSectionClick}>Add Section</Button>
            </div>
          </>
        ) :(
          <div className="flex flex-col items-center justify-center rounded-lg shadow p-4 border border-gray-300 min-h-[128px] bg-white gap-4">
            <span className="text-2xl text-gray-500 font-bold">Content Block is Empty</span>
            <div className="flex gap-2">
              <Button className="!text-base" onClick={handleAddNewSectionClick}>Add Section</Button>
            </div>
          </div>
        )}
      </div>

      <pre className="bg-white border border-dashed border-gray-400 rounded-lg shadow p-4 mt-12">rows: {JSON.stringify(rows, null, 2)}</pre>
    </>
  );
};
