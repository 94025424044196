import { DummyElement, HeadingElement, JumpAnchorElement } from "./components";

export const Element = ({ id, data, onChange }) => {
  switch (data?.name) {
    case 'Heading':
      return <HeadingElement id={id} data={data} onChange={onChange} />

    case 'Jump Anchor':
      return <JumpAnchorElement id={id} data={data} onChange={onChange} />

    default:
      return <DummyElement id={id} name={data?.name}/>;
  }
};