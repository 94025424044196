import { classNames } from "../../utils";
import { ConditionalWrapper } from "..";


export const Button = ({className, theme="blue", icon, tooltipText, disabled, onClick, children }) => (
  <ConditionalWrapper
    condition={tooltipText}
    wrapper={(children) => (
      <div className={classNames(['group inline-block relative', className])}>
        {children}
        <div className="group-hover:opacity-100 transition-opacity bg-white text-sm text-gray-800 shadow border border-gray-300 rounded-md absolute left-1/2 py-0.5 px-2
      -translate-x-1/2 opacity-0 -top-1 -translate-y-full whitespace-nowrap">{tooltipText}</div>
      </div>
    )}
  >
    <button
      className={classNames([
        'rounded-md text-white text-xs focus:outline-none',
        icon ? 'p-1' : 'px-2 py-1',
        theme === 'blue' && 'bg-indigo-700',
        (theme === 'blue' && !disabled) && 'hover:bg-indigo-600',
        theme === 'red' && 'bg-red-700',
        (theme === 'red' && !disabled) && 'hover:bg-red-600',
        disabled && 'cursor-not-allowed opacity-60',
        !tooltipText && className
      ])}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  </ConditionalWrapper>
);
