export const getRowTitle = (items) => items?.map((item) => {
  if (item?.data?.name === 'Heading') {
    const strArr = [];
    if (item.data?.headingLevel) {
      strArr.push(item.data.headingLevel)
    }
    if (item.data?.headingText) {
      strArr.push(item.data.headingText)
    }
    return `${item.data?.headingLevel && `${item.data?.headingLevel} `}Heading${item.data?.headingText && ` <small>(${item.data?.headingText})</small>`}`;
  }
  return item?.data?.name;
}).join(', ');
