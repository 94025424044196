export const elements = [
    'Alert Box',
    'Available languages, main countries and excluded countries',
    'Bonus List Bottom',
    'Bonus List Top',
    'Bonus Tiles',
    'Casino Reviews',
    'Category Tiles',
    'Comparison Elements',
    'Content',
    'Conversion (one operator)',
    'Country table with big list',
    'Deposit methods, withdrawal methods and currencies',
    'FAQs',
    'Game Reviews',
    'Game Tiles',
    'Heading',
    'How To',
    'Image',
    'Jump Anchor',
    'Jump navigation with Author Quote',
    'Latest Articles',
    'License table with big list',
    'List of available games',
    'List of available languages',
    'List of not recommended casinos',
    'List of out of business casinos',
    'List with Background Image',
    'List with Icons / Numbers',
    'List with Images',
    'Logos',
    'More on this topic',
    'Payment method table with big list',
    'Pros and Cons',
    'Ranking',
    'Reference List',
    'RichText Table',
    'Side by side Text',
    'Simple List with Images',
    'Slot Ranking',
    'Slot Reviews',
    'Software table with big list',
    'Top3 Operators',
    'Video'
]