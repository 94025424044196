import { Button, ElementSlider } from "../";
import { useState } from "react";
import { AddIcon } from "../../icons";

export const AddElementButton = ({className, icon, parentId, addElementCallBack}) => {
  const [elementSliderIsVisible, setElementSliderIsVisible] = useState(false);

  const handleCloseElementSliderCallback = () => {
    setElementSliderIsVisible(false);
  }

  const handleAddNewElementCallback = (elementName) => {
    setElementSliderIsVisible(false);
    addElementCallBack(elementName, parentId);
  }

  return (
    <>
      <Button className={className} icon={icon} tooltipText={icon ? 'Add New Element' : undefined}  onClick={() => setElementSliderIsVisible(true)}>{icon ? <AddIcon className="h-4 w-4" /> : 'Add Element'}</Button>
      {elementSliderIsVisible && (
        <ElementSlider
          addElementCallBack={handleAddNewElementCallback}
          closeCallBack={handleCloseElementSliderCallback}
        />
      )}
    </>
  );
};
