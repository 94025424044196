import { useEffect, useState } from "react";

export const JumpAnchorElement = ({ id, data, onChange }) => {
  const [formState, setFormState] = useState(data);

  const handleInputOnChange = ({ target }) => {
    const { name, value } = target;

    setFormState({
      ...formState,
      [name]: value
    })
  };

  useEffect(() => {
    setFormState({
      jumpId: ''
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!onChange) {
      return;
    }
  
    onChange(id, formState)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState]);

  return (
    <div className="pb-6">
      <div className="flex gap-8">
        <label className="block flex-1">
          <div className="flex justify-between">
            <span className="text-gray-700">Jump Id <small className="text-gray-400">(excluding #)</small></span>
            <span className="text-gray-400">0 out of 255</span>
          </div> 
          <input
            placeholder="Jump Id"
            name="jumpId"
            value={formState.jumpId || ''}
            type="text"
            maxLength="255"
            className="block w-full rounded-md focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 mt-1 disabled:opacity-50 border-gray-300"
            onChange={handleInputOnChange}
          />
        </label>
      </div>
    </div>
  );
};