import { useEffect, useState } from "react";

export const HeadingElement = ({ id, data, onChange }) => {
  const [formState, setFormState] = useState(data);

  const handleInputOnChange = ({ target }) => {
    const { name, value } = target;

    setFormState({
      ...formState,
      [name]: value
    })
  };

  useEffect(() => {
    setFormState({
      headingText: '',
      headingLevel: 'H2'
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!onChange) {
      return;
    }
  
    onChange(id, formState)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState]);

  return (
    <div className="pb-6">
      <div className="flex gap-8">
        <label className="block flex-1">
          <div className="flex justify-between">
            <span className="text-gray-700">Heading Title</span>
            <span className="text-gray-400">0 out of 255</span>
          </div> 
          <input
            placeholder="Heading Title text"
            name="headingText"
            value={formState.headingText || ''}
            type="text"
            maxLength="255"
            className="block w-full rounded-md focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 mt-1 disabled:opacity-50 border-gray-300"
            onChange={handleInputOnChange}
          />
        </label>

        <label className="block flex-1">
          <span className="text-gray-700">Heading Level</span>
          <select
            name="headingLevel"
            className="block w-full border-gray-300 rounded-md focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 mt-1"
            onChange={handleInputOnChange}
            value={formState.headingLevel}
          >
            <option value="H2">H2</option>
            <option value="H3">H3</option>
            <option value="H4">H4</option>
          </select>
        </label>
      </div>
    </div>
  );
};