import { useState } from "react";
import { elements } from "../../constants";
import { Arrow, Close, Search } from "../../icons";

export const ElementSlider = ({addElementCallBack, closeCallBack}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleInputOnChange = ({ target }) => {
    const { value } = target;

    setSearchTerm(value);
  };

  return (
    <div className="fixed inset-0 overflow-hidden z-50">
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-all transform" onClick={closeCallBack}></div>
        <section className="absolute inset-y-0 right-0 pl-10 max-w-full flex">
          <div className="relative w-screen max-w-xl">
            <div className="absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4">
              <button
                type="button"
                className="text-gray-300 hover:text-white transition ease-in-out duration-150"
                onClick={closeCallBack}
              >
                <Close className="h-6 w-6" />
              </button>
            </div>
            <div className="h-full flex flex-col space-y-6 py-6 bg-white shadow-xl overflow-y-scroll">
              <div className="relative flex-1 px-4 sm:px-6">
                <div className="absolute inset-0 px-4 sm:px-6">
                  <div className="mb-2 py-3 flex flex-row items-center">
                    <Search className="w-5 h-5" />
                    <input
                      placeholder="Search"
                      type="text"
                      className="border-0 bg-transparent ml-3 py-2 px-4 flex-1 transition focus:border-b focus:outline-none text-lg"
                      value={searchTerm}
                      onChange={handleInputOnChange}
                    />
                  </div>
                  <ul className="w-full space-y border-t border-b">
                    {elements.filter((item) => !searchTerm || (searchTerm && item.toLowerCase().includes(searchTerm?.toLowerCase()))).map((item, index) => (
                      <li
                        key={index}
                        className="rounded flex flex-row py-5 px-3 group hover:bg-gray-200 transition duration-150 cursor-pointer justify-between"
                        onClick={() => addElementCallBack(item)}
                      >
                        <div className="flex flex-row items-center">
                          <p className="text-gray-700 group-hover:text-gray-800">
                            {item}
                          </p>
                        </div>
                        <span className="text-gray-300 transition transform group-hover:translate-x-2 group-hover:text-gray-600 duration-150 ease-out">
                          <Arrow className="w-5 h-5 -rotate-90" />
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
