import { Element, RowTypeBadge, SectionForm } from "./components";

import { useDndMonitor } from "@dnd-kit/core";
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { Arrow, Bars, CollapseAllIcon, DeleteIcon, ExpandAllIcon } from "../../icons";
import { Button } from "../Button/Button";
import { classNames, getRowTitle } from "../../utils";
import { AddElementButton } from "..";


export const Row = ({
  id,
  type,
  isOpen,
  data,
  childrens,
  level,
  draggedRowData,
  addElementCallBack,
  handleDeleteButtonClick,
  toggleOpen,
  toggleOpenInSections,
  setDraggedRowData,
  handleDataChange
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id,
    data: {
      ...data,
      level
    }
  });

  useDndMonitor({
    onDragStart(event) {
      setDraggedRowData({
        id: event?.active?.id,
        level: event?.active?.data?.current?.level
      })
    },
    onDragEnd() {
      setDraggedRowData()
    },
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition
  };

  const isDraggable = !draggedRowData?.level || draggedRowData?.level === level;

  return (
    <div
      className={classNames([
        'relative bg-white border border-gray-300 rounded-lg shadow',
        id === draggedRowData?.id && 'z-10'
      ])}
      ref={isDraggable ? setNodeRef : undefined}
      style={style}
    >
      <div className="flex items-center flex-wrap p-3 rounded-lg">
        <div
          className={classNames([
            'mr-4 p-1 cursor-move hover:bg-gray-200 transition-colors rounded-md border border-transparent hover:border-gray-300',
            id === draggedRowData?.id && 'bg-gray-200 border-gray-300'
          ])}
          {...(isDraggable ? {...attributes} : {})}
          {...(isDraggable ? {...listeners} : {})}
        >
          <Bars className="w-6 h-6 inline-block" />
        </div>
        {(type === 'element') ? (
          <div dangerouslySetInnerHTML={{__html: getRowTitle([{data}])}} />
        ) : (
          !childrens.length ? (
            <div className="text-gray-500">Empty Section</div>
          ) : (
            <div dangerouslySetInnerHTML={{__html: getRowTitle(childrens)}} />
          )
        )}
        <RowTypeBadge type={type} elementsCount={childrens?.length} />
        <div className="ml-4 p-1 cursor-pointer hover:bg-gray-200 transition-colors rounded-md border border-transparent hover:border-gray-300" onClick={() => toggleOpen(id)}>
          <Arrow className={`h-4 w-4 ${isOpen && 'rotate-180'}`} />
        </div>
      </div>
      <div className={`relative p-4 ${!isOpen && 'hidden'}`}>
        <small className="absolute text-gray-500 bottom-0.5 right-1">id: {id}</small>
        {type === 'section' && (
          <>
            <div className="flex gap-8">
              <SectionForm id={id} data={data} onChange={handleDataChange} />
            </div>
            <div className="flex flex-col gap-2 mt-6">
              <SortableContext 
                items={childrens}
                strategy={verticalListSortingStrategy}
              >
                {childrens?.map((item) => (
                  <Row
                    key={item.id}
                    id={item.id}
                    type={item.type}
                    isOpen={item.isOpen}
                    data={item.data}
                    childrens={item.childrens} // not needed, undefined
                    level={2}
                    draggedRowData={draggedRowData}
                    addElementCallBack={addElementCallBack}
                    handleDeleteButtonClick={handleDeleteButtonClick}
                    toggleOpen={toggleOpen}
                    toggleOpenInSections={toggleOpenInSections}
                    setDraggedRowData={setDraggedRowData}
                    handleDataChange={handleDataChange}
                  />
                ))}
              </SortableContext>
            </div>
            <div className="flex gap-1 mt-4">
              <Button icon tooltipText="Delete Section" theme="red" onClick={() => handleDeleteButtonClick(id)}><DeleteIcon className="h-4 w-4" /></Button>
              <AddElementButton icon parentId={id} addElementCallBack={addElementCallBack} />
              <Button icon tooltipText="Expand Elements" onClick={() => toggleOpenInSections(true, id)} disabled={!childrens?.length}><ExpandAllIcon className="h-4 w-4" /></Button>
              <Button icon tooltipText="Collapse Elements" onClick={() => toggleOpenInSections(false, id)} disabled={!childrens?.length}><CollapseAllIcon className="h-4 w-4" /></Button>
            </div>
          </>
        )}

        {type === 'element' && (
          <>
            <Element id={id} data={data} onChange={handleDataChange} />
            <Button icon tooltipText="Delete Element" className="mt-4" theme="red" onClick={() => handleDeleteButtonClick(id)}><DeleteIcon className="h-4 w-4" /></Button>
          </>
        )}
      </div>
    </div>
  );
}
